import React, { useState } from "react";
import styled from "styled-components";
import { FaBars, FaInfoCircle, FaMap } from "react-icons/fa";
import { ExploreProvider } from "../../context/ExploreContext";
import ActualSwitcher from "./ActualSwitcher";
import Map from "./Map/Map";
import Button from "../Inputs/Button";
import Cookies from "universal-cookie";
import Linktext from "../General/Linktext";

const Explore = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [mapConsent, setMapConsent] = useState(false);
  const mobile = window.innerWidth < 740;
  const cookies = new Cookies(null, { path: "/" });
  console.log(cookies.get("map-consent"));
  return (
    <ExploreProvider>
      <Header>
        <div
          onClick={() => setShowSidebar(!showSidebar)}
          style={{ width: "40px", display: "flex", alignItems: "center" }}
        >
          {showSidebar ? (
            <FaMap
              color="white"
              size="1.2em"
              style={{ paddingLeft: "20px", cursor: "pointer" }}
            />
          ) : (
            <FaBars
              color="white"
              size="1.2em"
              style={{ paddingLeft: "20px", cursor: "pointer" }}
            />
          )}
        </div>
        <div style={{ width: "100px" }}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1473.17 372.09">
            <g data-name="Layer 2">
              <path
                d="M647.44 108.49c14.52 0 26.47 2.35 35.86 7.04 9.39 4.7 16.58 9.75 21.56 15.16s8.04 9.11 9.18 11.1h3.42v-29.03h64.89v226.26h-64.89v-29.03h-3.42c-1.14 2-4.2 5.7-9.18 11.1-4.98 5.41-12.17 10.46-21.56 15.16-9.39 4.7-21.34 7.04-35.86 7.04-16.8 0-31.38-3.35-43.76-10.03-12.38-6.68-22.7-15.65-30.95-26.89-8.26-11.24-14.45-23.83-18.57-37.78-4.13-13.94-6.19-28.18-6.19-42.69s2.06-28.74 6.19-42.69c4.12-13.94 10.31-26.54 18.57-37.78 8.25-11.24 18.57-20.2 30.95-26.89 12.38-6.69 26.96-10.03 43.76-10.03zM666.22 161c-11.67 0-21.42 3.2-29.24 9.61-7.83 6.4-13.66 14.59-17.5 24.55-3.84 9.97-5.76 20.21-5.76 30.74s1.92 20.78 5.76 30.74c3.84 9.97 9.67 18.14 17.5 24.55 7.82 6.4 17.57 9.6 29.24 9.6s21.41-3.2 29.24-9.6c7.82-6.4 13.66-14.58 17.5-24.55 3.84-9.96 5.76-20.2 5.76-30.74s-1.92-20.77-5.76-30.74c-3.84-9.96-9.68-18.14-17.5-24.55-7.83-6.4-17.58-9.61-29.24-9.61zM898.35 343.28c-15.94 0-29.1-3.42-39.49-10.24-10.39-6.83-18.14-16.01-23.27-27.53-5.12-11.53-7.68-24.55-7.68-39.06V112.76h64.46v140.88c0 10.82 2.84 19.57 8.54 26.26 5.69 6.69 14.09 10.03 25.19 10.03 9.39 0 16.86-2.28 22.41-6.83 5.55-4.55 9.61-10.39 12.17-17.5 2.56-7.11 3.84-14.65 3.84-22.63V112.76h64.89v226.26h-64.89V307h-2.56c-4.84 8.82-12.31 17.08-22.41 24.76-10.11 7.68-23.84 11.53-41.19 11.53z"
                style={{ strokeWidth: "0px", fill: "white" }}
              ></path>
              <path
                d="M505.45 147.12c-4.98-11.53-12.6-20.84-22.84-27.96-10.25-7.11-23.2-10.67-38.85-10.67-12.53 0-23.41 2.07-32.66 6.19-9.25 4.13-16.93 9.39-23.05 15.79-6.12 6.4-11.03 12.88-14.73 19.42h-.86c-4.84-11.67-12.45-21.49-22.84-29.46-10.39-7.96-24.12-11.95-41.19-11.95s-30.03 3.78-39.7 11.31c-9.68 7.55-16.94 15.44-21.77 23.7h-2.13v-30.74h-20.57l-44.32 97.85v128.41h64.89V205.39c0-7.11 1.21-14.02 3.63-20.71 2.41-6.68 6.26-12.17 11.53-16.44 5.26-4.27 12.17-6.4 20.71-6.4 11.1 0 19.42 3.63 24.97 10.89s8.32 16.44 8.32 27.53V339h64.89V205.38c0-7.11 1.21-14.02 3.63-20.71 2.41-6.68 6.26-12.17 11.53-16.44 5.26-4.27 12.17-6.4 20.71-6.4 11.1 0 19.42 3.63 24.97 10.89 5.55 7.26 8.32 16.44 8.32 27.53v138.74h64.89V184.02c0-13.09-2.49-25.4-7.47-36.93z"
                style={{ strokeWidth: "0px", fill: "white" }}
              ></path>
              <path
                d="M1109.06 56.83v55.92h-38.42v51.66h38.42v99.89c0 7.68.86 16.08 2.56 25.19 1.71 9.11 5.26 17.72 10.67 25.83 5.4 8.11 13.38 14.8 23.91 20.07 10.52 5.26 24.61 7.9 42.26 7.9 10.24 0 23.05-1.28 38.42-3.84V289.5c-8.54 1.71-15.37 2.56-20.49 2.56-11.67 0-20-2.56-24.97-7.68-4.98-5.12-7.47-12.38-7.47-21.77V153l-43.56-96.16h-21.33zM1459.3 168.04c-9.25-17.78-22.7-32.16-40.35-43.12-17.65-10.95-39.13-16.44-64.46-16.44s-46.82 5.48-64.46 16.44c-17.65 10.96-31.1 25.33-40.34 43.12-2.08 3.99-3.89 8.08-5.5 12.22l71.5 157.83c11.75 3.42 24.65 5.19 38.81 5.19 25.33 0 46.81-5.48 64.46-16.44 17.64-10.95 31.09-25.33 40.35-43.12 9.24-17.78 13.87-37.07 13.87-57.84s-4.63-40.06-13.87-57.85zm-57.85 89.65c-4.27 9.82-10.32 17.72-18.14 23.69-7.83 5.98-17.44 8.97-28.82 8.97s-21.06-2.99-29.03-8.97c-7.97-5.98-14.02-13.87-18.15-23.69-4.13-9.82-6.19-20.42-6.19-31.8s2.06-22.34 6.19-32.02c4.12-9.67 10.17-17.5 18.15-23.48 7.96-5.98 17.64-8.97 29.03-8.97s20.99 2.99 28.82 8.97c7.82 5.98 13.88 13.81 18.14 23.48 4.27 9.68 6.41 20.35 6.41 32.02s-2.14 21.98-6.41 31.8z"
                style={{ strokeWidth: "0px", fill: "white" }}
              ></path>
              <path
                d="M0 372.09L72.35 372.09 240.91 0 168.55 0 0 372.09z"
                style={{ strokeWidth: "0px", fill: "white" }}
              ></path>
            </g>
          </svg>
        </div>
        <AboutContainer href="https://about.mauto.at">
          <span style={{ width: "25px", height: "25px" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
          </span>
          <AboutText>Was ist Mauto?</AboutText>
        </AboutContainer>
      </Header>
      <Container>
        <div style={{ width: showSidebar ? "auto" : "0px" }}>
          <ActualSwitcher
            setShowSidebar={(newValue) => setShowSidebar(newValue)}
            showSidebar={showSidebar}
            mobile={mobile}
          />
        </div>
        {!(showSidebar && mobile) &&
          (cookies.get("map-consent") === true ? (
            <Map />
          ) : (
            <div
              style={{
                backtroundColor: "white",
                background:
                  "linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), url(map.jpg)",
                backgroundSize: "cover",
                width: "100%",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                zIndex: 1000,
              }}
            >
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={() => {
                      cookies.set("map-consent", "true");
                      setMapConsent(true);
                    }}
                    text="Karte anzeigen"
                    Icon={FaMap}
                  />
                </div>
                <p style={{ textAlign: "center", fontSize: "14px" }}>
                  Durch Klick auf den Button stimmst du den{" "}
                  <a href="/privacy">Datenschutzbedingungen</a> zu.
                </p>
              </div>
            </div>
          ))}
      </Container>
      {mobile && !showSidebar && (
        <MapButtonConatiner>
          <Button
            Icon={FaInfoCircle}
            left={false}
            text="Details anzeigen"
            onClick={() => setShowSidebar(true)}
          />
        </MapButtonConatiner>
      )}
    </ExploreProvider>
  );
};

const Container = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
  display: flex;
  flex-direction: row;
`;

const Headline = styled.p`
  font-weight: 700;
  font-size: 21px;
  color: white;
  padding-left: 20px;
`;

const Header = styled.div`
  height: 64px;
  width: 100%;
  background-color: #165e16;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MapButtonConatiner = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  justify-content: center;
  display: flex;
`;

const AboutContainer = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-right: 20px;
  background-color: #d7f9d7;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  color: #0f260f;
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  &:hover {
    color: #d7f9d7;
    background-color: #0f260f;
    stroke: #d7f9d7;
  }
`;

const AboutText = styled.span`
  @media (max-width: 500px) {
    display: none;
  }
`;

export default Explore;
