import React from "react";
import styled from "styled-components";
import Footer from "../Footer/Footer";

const Privacy = () => {
  return (
    <Wrapper>
      <InnerWrapper>
        <HeadingWrapper>
          <h1>Datenschutzerklarung</h1>
          <Close href="/">X</Close>
        </HeadingWrapper>
        <h2 id="m716">Präambel</h2>
        <p>
          Mit der folgenden Datenschutzerklärung möchten wir Sie darüber
          aufklären, welche Arten Ihrer personenbezogenen Daten (nachfolgend
          auch kurz als &quot;Daten&quot; bezeichnet) wir zu welchen Zwecken und
          in welchem Umfang verarbeiten. Die Datenschutzerklärung gilt für alle
          von uns durchgeführten Verarbeitungen personenbezogener Daten, sowohl
          im Rahmen der Erbringung unserer Leistungen als auch insbesondere auf
          unseren Webseiten, in mobilen Applikationen sowie innerhalb externer
          Onlinepräsenzen, wie z.&nbsp;B. unserer Social-Media-Profile
          (nachfolgend zusammenfassend bezeichnet als
          &quot;Onlineangebot&quot;).
        </p>
        <p>Die verwendeten Begriffe sind nicht geschlechtsspezifisch.</p>
        <p>Stand: 27. Juni 2024</p>
        <h2>Inhaltsübersicht</h2>
        <ul className="index">
          <li>
            <a className="index-link" href="#m716">
              Präambel
            </a>
          </li>
          <li>
            <a className="index-link" href="#m3">
              Verantwortlicher
            </a>
          </li>
          <li>
            <a className="index-link" href="#m11">
              Kontakt Datenschutzbeauftragter
            </a>
          </li>
          <li>
            <a className="index-link" href="#mOverview">
              Übersicht der Verarbeitungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m2427">
              Maßgebliche Rechtsgrundlagen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m27">
              Sicherheitsmaßnahmen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m25">
              Übermittlung von personenbezogenen Daten
            </a>
          </li>
          <li>
            <a className="index-link" href="#m24">
              Internationale Datentransfers
            </a>
          </li>
          <li>
            <a className="index-link" href="#m12">
              Allgemeine Informationen zur Datenspeicherung und Löschung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m10">
              Rechte der betroffenen Personen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m317">
              Geschäftliche Leistungen
            </a>
          </li>
          <li>
            <a className="index-link" href="#m13">
              Geschäftsprozesse und -verfahren
            </a>
          </li>
          <li>
            <a className="index-link" href="#m326">
              Zahlungsverfahren
            </a>
          </li>
          <li>
            <a className="index-link" href="#m225">
              Bereitstellung des Onlineangebots und Webhosting
            </a>
          </li>
          <li>
            <a className="index-link" href="#m134">
              Einsatz von Cookies
            </a>
          </li>
          <li>
            <a className="index-link" href="#m328">
              Plug-ins und eingebettete Funktionen sowie Inhalte
            </a>
          </li>
          <li>
            <a className="index-link" href="#m15">
              Änderung und Aktualisierung
            </a>
          </li>
          <li>
            <a className="index-link" href="#m42">
              Begriffsdefinitionen
            </a>
          </li>
        </ul>
        <h2 id="m3">Verantwortlicher</h2>
        <p>
          Zewas GmbH
          <br />
          Müselbach 491a
          <br />
          6861 Alberschwende
          <br />
          Österreich
        </p>
        <p>Vertretungsberechtigte Personen: Niklas Mischi</p>
        <p>
          E-Mail-Adresse: <a href="mailto:office@zewas.at">office@zewas.at</a>
        </p>
        <h2 id="m11">Kontakt Datenschutzbeauftragter</h2>
        <p>
          Niklas Mischi
          <br />
          Herrengutgasse 20a
          <br />
          6923 Lauterach
          <br />
          Email:
          <a href="mailto:office@zewas.at">office@zewas.at</a>
        </p>
        <h2 id="mOverview">Übersicht der Verarbeitungen</h2>
        <p>
          Die nachfolgende Übersicht fasst die Arten der verarbeiteten Daten und
          die Zwecke ihrer Verarbeitung zusammen und verweist auf die
          betroffenen Personen.
        </p>
        <h3>Arten der verarbeiteten Daten</h3>
        <ul>
          <li>Bestandsdaten.</li>
          <li>Beschäftigtendaten.</li>
          <li>Zahlungsdaten.</li>
          <li>Standortdaten.</li>
          <li>Kontaktdaten.</li>
          <li>Inhaltsdaten.</li>
          <li>Vertragsdaten.</li>
          <li>Nutzungsdaten.</li>
          <li>Meta-, Kommunikations- und Verfahrensdaten.</li>
          <li>Protokolldaten.</li>
        </ul>
        <h3>Kategorien betroffener Personen</h3>
        <ul>
          <li>Leistungsempfänger und Auftraggeber.</li>
          <li>Beschäftigte.</li>
          <li>Interessenten.</li>
          <li>Kommunikationspartner.</li>
          <li>Nutzer.</li>
          <li>Geschäfts- und Vertragspartner.</li>
        </ul>
        <h3>Zwecke der Verarbeitung</h3>
        <ul>
          <li>
            Erbringung vertraglicher Leistungen und Erfüllung vertraglicher
            Pflichten.
          </li>
          <li>Kommunikation.</li>
          <li>Sicherheitsmaßnahmen.</li>
          <li>Büro- und Organisationsverfahren.</li>
          <li>Organisations- und Verwaltungsverfahren.</li>
          <li>
            Bereitstellung unseres Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>Begründung und Durchführung von Beschäftigungsverhältnissen.</li>
          <li>Informationstechnische Infrastruktur.</li>
          <li>Geschäftsprozesse und betriebswirtschaftliche Verfahren.</li>
        </ul>
        <h2 id="m2427">Maßgebliche Rechtsgrundlagen</h2>
        <p>
          <strong>Maßgebliche Rechtsgrundlagen nach der DSGVO: </strong>Im
          Folgenden erhalten Sie eine Übersicht der Rechtsgrundlagen der DSGVO,
          auf deren Basis wir personenbezogene Daten verarbeiten. Bitte nehmen
          Sie zur Kenntnis, dass neben den Regelungen der DSGVO nationale
          Datenschutzvorgaben in Ihrem bzw. unserem Wohn- oder Sitzland gelten
          können. Sollten ferner im Einzelfall speziellere Rechtsgrundlagen
          maßgeblich sein, teilen wir Ihnen diese in der Datenschutzerklärung
          mit.
        </p>
        <ul>
          <li>
            <strong>Einwilligung (Art. 6 Abs. 1 S. 1 lit. a) DSGVO)</strong> -
            Die betroffene Person hat ihre Einwilligung in die Verarbeitung der
            sie betreffenden personenbezogenen Daten für einen spezifischen
            Zweck oder mehrere bestimmte Zwecke gegeben.
          </li>
          <li>
            <strong>
              Vertragserfüllung und vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1
              lit. b) DSGVO)
            </strong>
            - Die Verarbeitung ist für die Erfüllung eines Vertrags, dessen
            Vertragspartei die betroffene Person ist, oder zur Durchführung
            vorvertraglicher Maßnahmen erforderlich, die auf Anfrage der
            betroffenen Person erfolgen.
          </li>
          <li>
            <strong>
              Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO)
            </strong>
            - Die Verarbeitung ist zur Erfüllung einer rechtlichen Verpflichtung
            erforderlich, der der Verantwortliche unterliegt.
          </li>
          <li>
            <strong>
              Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO)
            </strong>{" "}
            - die Verarbeitung ist zur Wahrung der berechtigten Interessen des
            Verantwortlichen oder eines Dritten notwendig, vorausgesetzt, dass
            die Interessen, Grundrechte und Grundfreiheiten der betroffenen
            Person, die den Schutz personenbezogener Daten verlangen, nicht
            überwiegen.
          </li>
          <li>
            <strong>
              Verarbeitung besonderer Kategorien personenbezogener Daten in
              Bezug auf Gesundheitswesen, Beruf und soziale Sicherheit (Art. 9
              Abs. 2 lit. h) DSGVO)
            </strong>
            - Die Verarbeitung ist für Zwecke der Gesundheitsvorsorge oder der
            Arbeitsmedizin, für die Beurteilung der Arbeitsfähigkeit des
            Beschäftigten, für die medizinische Diagnostik, die Versorgung oder
            Behandlung im Gesundheits- oder Sozialbereich oder für die
            Verwaltung von Systemen und Diensten im Gesundheits- oder
            Sozialbereich auf der Grundlage des Unionsrechts oder des Rechts
            eines Mitgliedstaats oder aufgrund eines Vertrags mit einem
            Angehörigen eines Gesundheitsberufs erforderlich.
          </li>
        </ul>
        <p>
          <strong>Nationale Datenschutzregelungen in Österreich: </strong>
          Zusätzlich zu den Datenschutzregelungen der DSGVO gelten nationale
          Regelungen zum Datenschutz in Österreich. Hierzu gehört insbesondere
          das Bundesgesetz zum Schutz natürlicher Personen bei der Verarbeitung
          personenbezogener Daten (Datenschutzgesetz – DSG). Das
          Datenschutzgesetz enthält insbesondere Spezialregelungen zum Recht auf
          Auskunft, zum Recht auf Richtigstellung oder Löschung, zur
          Verarbeitung besonderer Kategorien personenbezogener Daten, zur
          Verarbeitung für andere Zwecke und zur Übermittlung sowie zur
          automatisierten Entscheidungsfindung im Einzelfall.
        </p>
        <p>
          <strong>Hinweis auf Geltung DSGVO und Schweizer DSG: </strong>Diese
          Datenschutzhinweise dienen sowohl der Informationserteilung nach dem
          Schweizer DSG als auch nach der Datenschutzgrundverordnung (DSGVO).
          Aus diesem Grund bitten wir Sie zu beachten, dass aufgrund der
          breiteren räumlichen Anwendung und Verständlichkeit die Begriffe der
          DSGVO verwendet werden. Insbesondere statt der im Schweizer DSG
          verwendeten Begriffe &quot;Bearbeitung&quot; von
          &quot;Personendaten&quot;, &quot;überwiegendes Interesse&quot; und
          &quot;besonders schützenswerte Personendaten&quot; werden die in der
          DSGVO verwendeten Begriffe &quot;Verarbeitung&quot; von
          &quot;personenbezogenen Daten&quot; sowie &quot;berechtigtes
          Interesse&quot; und &quot;besondere Kategorien von Daten&quot;
          verwendet. Die gesetzliche Bedeutung der Begriffe wird jedoch im
          Rahmen der Geltung des Schweizer DSG weiterhin nach dem Schweizer DSG
          bestimmt.
        </p>
        <h2 id="m27">Sicherheitsmaßnahmen</h2>
        <p>
          Wir treffen nach Maßgabe der gesetzlichen Vorgaben unter
          Berücksichtigung des Stands der Technik, der Implementierungskosten
          und der Art, des Umfangs, der Umstände und der Zwecke der Verarbeitung
          sowie der unterschiedlichen Eintrittswahrscheinlichkeiten und des
          Ausmaßes der Bedrohung der Rechte und Freiheiten natürlicher Personen
          geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
          angemessenes Schutzniveau zu gewährleisten.
        </p>
        <p>
          Zu den Maßnahmen gehören insbesondere die Sicherung der
          Vertraulichkeit, Integrität und Verfügbarkeit von Daten durch
          Kontrolle des physischen und elektronischen Zugangs zu den Daten als
          auch des sie betreffenden Zugriffs, der Eingabe, der Weitergabe, der
          Sicherung der Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir
          Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten,
          die Löschung von Daten und Reaktionen auf die Gefährdung der Daten
          gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener
          Daten bereits bei der Entwicklung bzw. Auswahl von Hardware, Software
          sowie Verfahren entsprechend dem Prinzip des Datenschutzes, durch
          Technikgestaltung und durch datenschutzfreundliche Voreinstellungen.
        </p>
        <p>
          Sicherung von Online-Verbindungen durch
          TLS-/SSL-Verschlüsselungstechnologie (HTTPS): Um die Daten der Nutzer,
          die über unsere Online-Dienste übertragen werden, vor unerlaubten
          Zugriffen zu schützen, setzen wir auf die
          TLS-/SSL-Verschlüsselungstechnologie. Secure Sockets Layer (SSL) und
          Transport Layer Security (TLS) sind die Eckpfeiler der sicheren
          Datenübertragung im Internet. Diese Technologien verschlüsseln die
          Informationen, die zwischen der Website oder App und dem Browser des
          Nutzers (oder zwischen zwei Servern) übertragen werden, wodurch die
          Daten vor unbefugtem Zugriff geschützt sind. TLS, als die
          weiterentwickelte und sicherere Version von SSL, gewährleistet, dass
          alle Datenübertragungen den höchsten Sicherheitsstandards entsprechen.
          Wenn eine Website durch ein SSL-/TLS-Zertifikat gesichert ist, wird
          dies durch die Anzeige von HTTPS in der URL signalisiert. Dies dient
          als ein Indikator für die Nutzer, dass ihre Daten sicher und
          verschlüsselt übertragen werden.
        </p>
        <h2 id="m25">Übermittlung von personenbezogenen Daten</h2>
        <p>
          Im Rahmen unserer Verarbeitung von personenbezogenen Daten kommt es
          vor, dass diese an andere Stellen, Unternehmen, rechtlich
          selbstständige Organisationseinheiten oder Personen übermittelt
          beziehungsweise ihnen gegenüber offengelegt werden. Zu den Empfängern
          dieser Daten können z.&nbsp;B. mit IT-Aufgaben beauftragte
          Dienstleister gehören oder Anbieter von Diensten und Inhalten, die in
          eine Website eingebunden sind. In solchen Fällen beachten wir die
          gesetzlichen Vorgaben und schließen insbesondere entsprechende
          Verträge bzw. Vereinbarungen, die dem Schutz Ihrer Daten dienen, mit
          den Empfängern Ihrer Daten ab.
        </p>
        <h2 id="m24">Internationale Datentransfers</h2>
        <p>
          Datenverarbeitung in Drittländern: Sofern wir Daten in einem Drittland
          (d.&nbsp;h., außerhalb der Europäischen Union (EU), des Europäischen
          Wirtschaftsraums (EWR)) verarbeiten oder die Verarbeitung im Rahmen
          der Inanspruchnahme von Diensten Dritter oder der Offenlegung bzw.
          Übermittlung von Daten an andere Personen, Stellen oder Unternehmen
          stattfindet, erfolgt dies nur im Einklang mit den gesetzlichen
          Vorgaben. Sofern das Datenschutzniveau in dem Drittland mittels eines
          Angemessenheitsbeschlusses anerkannt wurde (Art. 45 DSGVO), dient
          dieser als Grundlage des Datentransfers. Im Übrigen erfolgen
          Datentransfers nur dann, wenn das Datenschutzniveau anderweitig
          gesichert ist, insbesondere durch Standardvertragsklauseln (Art. 46
          Abs. 2 lit. c) DSGVO), ausdrückliche Einwilligung oder im Fall
          vertraglicher oder gesetzlich erforderlicher Übermittlung (Art. 49
          Abs. 1 DSGVO). Im Übrigen teilen wir Ihnen die Grundlagen der
          Drittlandübermittlung bei den einzelnen Anbietern aus dem Drittland
          mit, wobei die Angemessenheitsbeschlüsse als Grundlagen vorrangig
          gelten. Informationen zu Drittlandtransfers und vorliegenden
          Angemessenheitsbeschlüssen können dem Informationsangebot der
          EU-Kommission entnommen werden:
          <a
            href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
            target="_blank"
          >
            https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
          </a>
        </p>
        <p>
          EU-US Trans-Atlantic Data Privacy Framework: Im Rahmen des sogenannten
          &quot;Data Privacy Framework&quot; (DPF) hat die EU-Kommission das
          Datenschutzniveau ebenfalls für bestimmte Unternehmen aus den USA im
          Rahmen der Angemessenheitsbeschlusses vom 10.07.2023 als sicher
          anerkannt. Die Liste der zertifizierten Unternehmen als auch weitere
          Informationen zu dem DPF können Sie der Website des
          Handelsministeriums der USA unter
          <a href="https://www.dataprivacyframework.gov/" target="_blank">
            https://www.dataprivacyframework.gov/
          </a>
          (in Englisch) entnehmen. Wir informieren Sie im Rahmen der
          Datenschutzhinweise, welche von uns eingesetzten Diensteanbieter unter
          dem Data Privacy Framework zertifiziert sind.
        </p>
        <h2 id="m12">
          Allgemeine Informationen zur Datenspeicherung und Löschung
        </h2>
        <p>
          Wir löschen personenbezogene Daten, die wir verarbeiten, gemäß den
          gesetzlichen Bestimmungen, sobald die zugrundeliegenden Einwilligungen
          widerrufen werden oder keine weiteren rechtlichen Grundlagen für die
          Verarbeitung bestehen. Dies betrifft Fälle, in denen der ursprüngliche
          Verarbeitungszweck entfällt oder die Daten nicht mehr benötigt werden.
          Ausnahmen von dieser Regelung bestehen, wenn gesetzliche Pflichten
          oder besondere Interessen eine längere Aufbewahrung oder Archivierung
          der Daten erfordern.
        </p>
        <p>
          Insbesondere müssen Daten, die aus handels- oder steuerrechtlichen
          Gründen aufbewahrt werden müssen oder deren Speicherung notwendig ist
          zur Rechtsverfolgung oder zum Schutz der Rechte anderer natürlicher
          oder juristischer Personen, entsprechend archiviert werden.
        </p>
        <p>
          Unsere Datenschutzhinweise enthalten zusätzliche Informationen zur
          Aufbewahrung und Löschung von Daten, die speziell für bestimmte
          Verarbeitungsprozesse gelten.
        </p>
        <p>
          Bei mehreren Angaben zur Aufbewahrungsdauer oder Löschungsfristen
          eines Datums, ist stets die längste Frist maßgeblich.
        </p>
        <p>
          Beginnt eine Frist nicht ausdrücklich zu einem bestimmten Datum und
          beträgt sie mindestens ein Jahr, so startet sie automatisch am Ende
          des Kalenderjahres, in dem das fristauslösende Ereignis eingetreten
          ist. Im Fall laufender Vertragsverhältnisse, in deren Rahmen Daten
          gespeichert werden, ist das fristauslösende Ereignis der Zeitpunkt des
          Wirksamwerdens der Kündigung oder sonstige Beendigung des
          Rechtsverhältnisses.
        </p>
        <p>
          Daten, die nicht mehr für den ursprünglich vorgesehenen Zweck, sondern
          aufgrund gesetzlicher Vorgaben oder anderer Gründe aufbewahrt werden,
          verarbeiten wir ausschließlich zu den Gründen, die ihre Aufbewahrung
          rechtfertigen.
        </p>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Aufbewahrung und Löschung von Daten: </strong>Die folgenden
            allgemeinen Fristen gelten gemäß österreichischem Recht für die
            Aufbewahrung und Archivierung:
            <ul>
              <li>
                10 Jahre - Aufbewahrungsfrist für Bücher und Aufzeichnungen,
                Jahresabschlüsse, Inventare, Lageberichte, Eröffnungsbilanzen,
                Buchungsbelege und Rechnungen sowie alle erforderlichen
                Arbeitsanweisungen und sonstigen Organisationsunterlagen
                (Bundesabgabenordnung (BAO §132), Unternehmensgesetzbuch (UGB
                §§190-212)).
              </li>
              <li>
                6 Jahre - Sonstige Geschäftsunterlagen: Erhaltene Handels- oder
                Geschäftsbriefe, Kopien der versendeten Handels- oder
                Geschäftsbriefe und andere Unterlagen, sofern sie für die Steuer
                relevant sind. Dazu gehören beispielsweise Stundenlohnscheine,
                Betriebsabrechnungsbögen, Kalkulationsunterlagen,
                Preisauszeichnungen und Lohnabrechnungsunterlagen, sofern sie
                nicht bereits Buchungsbelege und Kassenstreifen sind
                (Bundesabgabenordnung (BAO §132), Unternehmensgesetzbuch (UGB
                §§190-212)).
              </li>
              <li>
                3 Jahre - Daten, die erforderlich sind, um potenzielle
                Gewährleistungs- und Schadensersatzansprüche oder ähnliche
                vertragliche Ansprüche und Rechte zu berücksichtigen sowie damit
                verbundene Anfragen zu bearbeiten, basierend auf früheren
                Geschäftserfahrungen und üblichen Branchenpraktiken, werden für
                die Dauer der regulären gesetzlichen Verjährungsfrist von drei
                Jahren gespeichert (§§ 1478, 1480 ABGB).
              </li>
            </ul>
          </li>
        </ul>
        <h2 id="m10">Rechte der betroffenen Personen</h2>
        <p>
          Rechte der betroffenen Personen aus der DSGVO: Ihnen stehen als
          Betroffene nach der DSGVO verschiedene Rechte zu, die sich
          insbesondere aus Art. 15 bis 21 DSGVO ergeben:
        </p>
        <ul>
          <li>
            <strong>
              Widerspruchsrecht: Sie haben das Recht, aus Gründen, die sich aus
              Ihrer besonderen Situation ergeben, jederzeit gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten, die
              aufgrund von Art. 6 Abs. 1 lit. e oder f DSGVO erfolgt,
              Widerspruch einzulegen; dies gilt auch für ein auf diese
              Bestimmungen gestütztes Profiling. Werden die Sie betreffenden
              personenbezogenen Daten verarbeitet, um Direktwerbung zu
              betreiben, haben Sie das Recht, jederzeit Widerspruch gegen die
              Verarbeitung der Sie betreffenden personenbezogenen Daten zum
              Zwecke derartiger Werbung einzulegen; dies gilt auch für das
              Profiling, soweit es mit solcher Direktwerbung in Verbindung
              steht.
            </strong>
          </li>
          <li>
            <strong>Widerrufsrecht bei Einwilligungen:</strong> Sie haben das
            Recht, erteilte Einwilligungen jederzeit zu widerrufen.
          </li>
          <li>
            <strong>Auskunftsrecht:</strong> Sie haben das Recht, eine
            Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet
            werden und auf Auskunft über diese Daten sowie auf weitere
            Informationen und Kopie der Daten entsprechend den gesetzlichen
            Vorgaben.
          </li>
          <li>
            <strong>Recht auf Berichtigung:</strong> Sie haben entsprechend den
            gesetzlichen Vorgaben das Recht, die Vervollständigung der Sie
            betreffenden Daten oder die Berichtigung der Sie betreffenden
            unrichtigen Daten zu verlangen.
          </li>
          <li>
            <strong>
              Recht auf Löschung und Einschränkung der Verarbeitung:
            </strong>{" "}
            Sie haben nach Maßgabe der gesetzlichen Vorgaben das Recht, zu
            verlangen, dass Sie betreffende Daten unverzüglich gelöscht werden,
            bzw. alternativ nach Maßgabe der gesetzlichen Vorgaben eine
            Einschränkung der Verarbeitung der Daten zu verlangen.
          </li>
          <li>
            <strong>Recht auf Datenübertragbarkeit:</strong> Sie haben das
            Recht, Sie betreffende Daten, die Sie uns bereitgestellt haben, nach
            Maßgabe der gesetzlichen Vorgaben in einem strukturierten, gängigen
            und maschinenlesbaren Format zu erhalten oder deren Übermittlung an
            einen anderen Verantwortlichen zu fordern.
          </li>
          <li>
            <strong>Beschwerde bei Aufsichtsbehörde:</strong> Sie haben
            unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs das Recht auf Beschwerde bei einer
            Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres
            gewöhnlichen Aufenthaltsorts, ihres Arbeitsplatzes oder des Orts des
            mutmaßlichen Verstoßes, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            Vorgaben der DSGVO verstößt.
          </li>
        </ul>
        <h2 id="m317">Geschäftliche Leistungen</h2>
        <p>
          Wir verarbeiten Daten unserer Vertrags- und Geschäftspartner,
          z.&nbsp;B. Kunden und Interessenten (zusammenfassend als
          &quot;Vertragspartner&quot; bezeichnet), im Rahmen von vertraglichen
          und vergleichbaren Rechtsverhältnissen sowie damit verbundenen
          Maßnahmen und im Hinblick auf die Kommunikation mit den
          Vertragspartnern (oder vorvertraglich), etwa zur Beantwortung von
          Anfragen.
        </p>
        <p>
          Wir verwenden diese Daten, um unsere vertraglichen Verpflichtungen zu
          erfüllen. Dazu gehören insbesondere die Pflichten zur Erbringung der
          vereinbarten Leistungen, etwaige Aktualisierungspflichten und Abhilfe
          bei Gewährleistungs- und sonstigen Leistungsstörungen. Darüber hinaus
          verwenden wir die Daten zur Wahrung unserer Rechte und zum Zwecke der
          mit diesen Pflichten verbundenen Verwaltungsaufgaben sowie der
          Unternehmensorganisation. Zudem verarbeiten wir die Daten auf
          Grundlage unserer berechtigten Interessen sowohl an einer
          ordnungsgemäßen und betriebswirtschaftlichen Geschäftsführung als auch
          an Sicherheitsmaßnahmen zum Schutz unserer Vertragspartner und unseres
          Geschäftsbetriebs vor Missbrauch, Gefährdung ihrer Daten, Geheimnisse,
          Informationen und Rechte (z.&nbsp;B. zur Beteiligung von
          Telekommunikations-, Transport- und sonstigen Hilfsdiensten sowie
          Subunternehmern, Banken, Steuer- und Rechtsberatern,
          Zahlungsdienstleistern oder Finanzbehörden). Im Rahmen des geltenden
          Rechts geben wir die Daten von Vertragspartnern nur insoweit an Dritte
          weiter, als dies für die vorgenannten Zwecke oder zur Erfüllung
          gesetzlicher Pflichten erforderlich ist. Über weitere Formen der
          Verarbeitung, etwa zu Marketingzwecken, werden die Vertragspartner im
          Rahmen dieser Datenschutzerklärung informiert.
        </p>
        <p>
          Welche Daten für die vorgenannten Zwecke erforderlich sind, teilen wir
          den Vertragspartnern vor oder im Rahmen der Datenerhebung, z.&nbsp;B.
          in Onlineformularen, durch besondere Kennzeichnung (z.&nbsp;B. Farben)
          bzw. Symbole (z.&nbsp;B. Sternchen o. Ä.), oder persönlich mit.
        </p>
        <p>
          Wir löschen die Daten nach Ablauf gesetzlicher Gewährleistungs- und
          vergleichbarer Pflichten, d. h. grundsätzlich nach vier Jahren, es sei
          denn, dass die Daten in einem Kundenkonto gespeichert werden,
          z.&nbsp;B., solange sie aus gesetzlichen Gründen der Archivierung
          aufbewahrt werden müssen (etwa für Steuerzwecke im Regelfall zehn
          Jahre). Daten, die uns im Rahmen eines Auftrags durch den
          Vertragspartner offengelegt wurden, löschen wir entsprechend den
          Vorgaben und grundsätzlich nach Ende des Auftrags.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.&nbsp;B.
            der vollständige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Zahlungsdaten (z.&nbsp;B. Bankverbindungen,
            Rechnungen, Zahlungshistorie); Kontaktdaten (z.&nbsp;B. Post- und
            E-Mail-Adressen oder Telefonnummern); Vertragsdaten (z.&nbsp;B.
            Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen). Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempfänger und
            Auftraggeber; Interessenten. Geschäfts- und Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erfüllung vertraglicher Pflichten;
            Sicherheitsmaßnahmen; Kommunikation; Büro- und
            Organisationsverfahren; Organisations- und Verwaltungsverfahren.
            Geschäftsprozesse und betriebswirtschaftliche Verfahren.
          </li>
          <li>
            <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
            Angaben im Abschnitt &quot;Allgemeine Informationen zur
            Datenspeicherung und Löschung&quot;.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);
            Rechtliche Verpflichtung (Art. 6 Abs. 1 S. 1 lit. c) DSGVO).
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Onlineshop, Bestellformulare, E-Commerce und Auslieferung:{" "}
            </strong>
            Wir verarbeiten die Daten unserer Kunden, um ihnen die Auswahl, den
            Erwerb, bzw. die Bestellung der gewählten Produkte, Waren sowie
            verbundener Leistungen, als auch deren Bezahlung und Zustellung,
            bzw. Ausführung zu ermöglichen. Sofern für die Ausführung einer
            Bestellung erforderlich, setzen wir Dienstleister, insbesondere
            Post-, Speditions- und Versandunternehmen ein, um die Lieferung,
            bzw. Ausführung gegenüber unseren Kunden durchzuführen. Für die
            Abwicklung der Zahlungsvorgänge nehmen wir die Dienste von Banken
            und Zahlungsdienstleistern in Anspruch. Die erforderlichen Angaben
            sind als solche im Rahmen des Bestell- bzw. vergleichbaren
            Erwerbsvorgangs gekennzeichnet und umfassen die zur Auslieferung,
            bzw. Zurverfügungstellung und Abrechnung benötigten Angaben sowie
            Kontaktinformationen, um etwaige Rücksprache halten zu können;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </span>
          </li>
          <li>
            <strong>Angebot von Software- und Plattformleistungen: </strong>Wir
            verarbeiten die Daten unserer Nutzer, angemeldeter und etwaiger
            Testnutzer (nachfolgend einheitlich als &quot;Nutzer&quot;
            bezeichnet), um ihnen gegenüber unsere vertraglichen Leistungen
            erbringen zu können sowie auf Grundlage berechtigter Interessen, um
            die Sicherheit unseres Angebotes gewährleisten und es
            weiterentwickeln zu können. Die erforderlichen Angaben sind als
            solche im Rahmen des Auftrags-, Bestell- bzw. vergleichbaren
            Vertragsschlusses gekennzeichnet und umfassen die zur
            Leistungserbringung und Abrechnung benötigten Angaben sowie
            Kontaktinformationen, um etwaige Rücksprachen halten zu können;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            </span>
          </li>
        </ul>
        <h2 id="m13">Geschäftsprozesse und -verfahren</h2>
        <p>
          Personenbezogene Daten von Leistungsempfängern und Auftraggebern –
          darunter Kunden, Klienten oder in speziellen Fällen Mandanten,
          Patienten oder Geschäftspartner sowie weitere Dritte – werden im
          Rahmen vertraglicher sowie vergleichbarer Rechtsverhältnisse und
          vorvertraglicher Maßnahmen wie der Anbahnung von Geschäftsbeziehungen
          verarbeitet. Diese Datenverarbeitung unterstützt und erleichtert
          betriebswirtschaftliche Abläufe in Bereichen wie Kundenmanagement,
          Vertrieb, Zahlungsverkehr, Buchhaltung und Projektmanagement.
        </p>
        <p>
          Die erfassten Daten dienen dazu, vertragliche Verpflichtungen zu
          erfüllen und betriebliche Prozesse effizient zu gestalten. Hierzu
          gehört die Abwicklung von Geschäftstransaktionen, das Management von
          Kundenbeziehungen, die Optimierung von Vertriebsstrategien sowie die
          Gewährleistung interner Rechnungs- und Finanzprozesse. Zusätzlich
          unterstützen die Daten die Wahrung der Rechte des Verantwortlichen und
          fördern Verwaltungsaufgaben sowie die Organisation des Unternehmens.
        </p>
        <p>
          Personenbezogene Daten können an Dritte weitergegeben werden, sofern
          dies zur Erfüllung der genannten Zwecke oder gesetzlicher
          Verpflichtungen notwendig ist. Nach Ablauf bestimmter Fristen oder
          wenn die Daten nicht mehr benötigt werden, erfolgt eine Löschung. Dies
          betrifft auch Informationen, die aufgrund anderer Vorschriften (z.B.:
          steuerrechtlich) länger aufbewahrt werden müssen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Daten zum Kunden (wie
            vollständiger Name, Kontaktinformationen, Kundennummer);
            Zahlungsinformationen (wie Bankverbindungen, Rechnungen,
            Zahlungshistorie); Kontaktdaten (wie Post- und E-Mail-Adressen oder
            Telefonnummern); Inhalte (wie textliche oder bildliche Nachrichten
            und Beiträge sowie dazugehörige Informationen, etwa Angaben zur
            Autorenschaft oder Erstellungszeitpunkt); Vertragsdetails (wie
            Vertragsgegenstand, Laufzeit, Kundenkategorie);
            Nutzungsinformationen (wie Seitenaufrufe und Verweildauer,
            Klickpfade, Nutzungsintensität und -häufigkeit, verwendete
            Gerätetypen und Betriebssysteme, Interaktionen mit Inhalten und
            Funktionen). Meta-, Kommunikations- und Verfahrensdaten (wie
            IP-Adressen, Zeitangaben, Identifikationsnummern, beteiligte
            Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempfänger und
            Auftraggeber; Interessenten; Kommunikationspartner. Geschäfts- und
            Vertragspartner.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erfüllung vertraglicher Pflichten; Büro- und
            Organisationsverfahren. Geschäftsprozesse und
            betriebswirtschaftliche Verfahren.
          </li>
          <li>
            <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
            Angaben im Abschnitt &quot;Allgemeine Informationen zur
            Datenspeicherung und Löschung&quot;.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <h2 id="m326">Zahlungsverfahren</h2>
        <p>
          Im Rahmen von Vertrags- und sonstigen Rechtsbeziehungen, aufgrund
          gesetzlicher Pflichten oder sonst auf Grundlage unserer berechtigten
          Interessen bieten wir den betroffenen Personen effiziente und sichere
          Zahlungsmöglichkeiten an und setzen hierzu neben Banken und
          Kreditinstituten weitere Dienstleister ein (zusammenfassend
          &quot;Zahlungsdienstleister&quot;).
        </p>
        <p>
          Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören
          Bestandsdaten, wie z.&nbsp;B. der Name und die Adresse, Bankdaten, wie
          z.&nbsp;B. Kontonummern oder Kreditkartennummern, Passwörter, TANs und
          Prüfsummen sowie die Vertrags-, Summen- und empfängerbezogenen
          Angaben. Die Angaben sind erforderlich, um die Transaktionen
          durchzuführen. Die eingegebenen Daten werden jedoch nur durch die
          Zahlungsdienstleister verarbeitet und bei diesen gespeichert.
          D.&nbsp;h., wir erhalten keine konto- oder kreditkartenbezogenen
          Informationen, sondern lediglich Informationen mit Bestätigung oder
          Negativbeauskunftung der Zahlung. Unter Umständen werden die Daten
          seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien
          übermittelt. Diese Übermittlung bezweckt die Identitäts- und
          Bonitätsprüfung. Hierzu verweisen wir auf die AGB und die
          Datenschutzhinweise der Zahlungsdienstleister.
        </p>
        <p>
          Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die
          Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche
          innerhalb der jeweiligen Webseiten bzw. Transaktionsapplikationen
          abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer
          Informationen und Geltendmachung von Widerrufs-, Auskunfts- und
          anderen Betroffenenrechten.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Bestandsdaten (z.&nbsp;B.
            der vollständige Name, Wohnadresse, Kontaktinformationen,
            Kundennummer, etc.); Zahlungsdaten (z.&nbsp;B. Bankverbindungen,
            Rechnungen, Zahlungshistorie); Vertragsdaten (z.&nbsp;B.
            Vertragsgegenstand, Laufzeit, Kundenkategorie); Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen). Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Leistungsempfänger und
            Auftraggeber; Geschäfts- und Vertragspartner. Interessenten.
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Erbringung vertraglicher
            Leistungen und Erfüllung vertraglicher Pflichten. Geschäftsprozesse
            und betriebswirtschaftliche Verfahren.
          </li>
          <li>
            <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
            Angaben im Abschnitt &quot;Allgemeine Informationen zur
            Datenspeicherung und Löschung&quot;.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
            vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO).
            Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>Stripe: </strong>Zahlungsdienstleistungen (technische
            Anbindung von Online-Bezahlmethoden);{" "}
            <strong>Dienstanbieter:</strong> Stripe, Inc., 510 Townsend Street,
            San Francisco, CA 94103, USA;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Vertragserfüllung und
              vorvertragliche Anfragen (Art. 6 Abs. 1 S. 1 lit. b) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://stripe.com" target="_blank">
              https://stripe.com
            </a>
            ;<strong>Datenschutzerklärung:</strong>
            <a href="https://stripe.com/de/privacy" target="_blank">
              https://stripe.com/de/privacy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF).
          </li>
        </ul>
        <h2 id="m225">Bereitstellung des Onlineangebots und Webhosting</h2>
        <p>
          Wir verarbeiten die Daten der Nutzer, um ihnen unsere Online-Dienste
          zur Verfügung stellen zu können. Zu diesem Zweck verarbeiten wir die
          IP-Adresse des Nutzers, die notwendig ist, um die Inhalte und
          Funktionen unserer Online-Dienste an den Browser oder das Endgerät der
          Nutzer zu übermitteln.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen); Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen). Protokolldaten
            (z.&nbsp;B. Logfiles betreffend Logins oder den Abruf von Daten oder
            Zugriffszeiten.).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit; Informationstechnische
            Infrastruktur (Betrieb und Bereitstellung von Informationssystemen
            und technischen Geräten (Computer, Server etc.).).
            Sicherheitsmaßnahmen.
          </li>
          <li>
            <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
            Angaben im Abschnitt &quot;Allgemeine Informationen zur
            Datenspeicherung und Löschung&quot;.
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Bereitstellung Onlineangebot auf eigener/ dedizierter
              Serverhardware:{" "}
            </strong>
            Für die Bereitstellung unseres Onlineangebotes nutzen wir von uns
            betriebene Serverhardware sowie den damit verbundenen Speicherplatz,
            die Rechenkapazität und die Software;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Erhebung von Zugriffsdaten und Logfiles: </strong>Der
            Zugriff auf unser Onlineangebot wird in Form von sogenannten
            &quot;Server-Logfiles&quot; protokolliert. Zu den Serverlogfiles
            können die Adresse und der Name der abgerufenen Webseiten und
            Dateien, Datum und Uhrzeit des Abrufs, übertragene Datenmengen,
            Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das
            Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite)
            und im Regelfall IP-Adressen und der anfragende Provider gehören.
            Die Serverlogfiles können zum einen zu Sicherheitszwecken eingesetzt
            werden, z.&nbsp;B. um eine Überlastung der Server zu vermeiden
            (insbesondere im Fall von missbräuchlichen Angriffen, sogenannten
            DDoS-Attacken), und zum anderen, um die Auslastung der Server und
            ihre Stabilität sicherzustellen;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).{" "}
            </span>
            <strong>Löschung von Daten:</strong> Logfile-Informationen werden
            für die Dauer von maximal 30 Tagen gespeichert und danach gelöscht
            oder anonymisiert. Daten, deren weitere Aufbewahrung zu
            Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des
            jeweiligen Vorfalls von der Löschung ausgenommen.
          </li>
          <li>
            <strong>Content-Delivery-Network: </strong>Wir setzen ein
            &quot;Content-Delivery-Network&quot; (CDN) ein. Ein CDN ist ein
            Dienst, mit dessen Hilfe Inhalte eines Onlineangebotes, insbesondere
            große Mediendateien, wie Grafiken oder Programm-Skripte, mit Hilfe
            regional verteilter und über das Internet verbundener Server
            schneller und sicherer ausgeliefert werden können;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Hetzner: </strong>Leistungen auf dem Gebiet der
            Bereitstellung von informationstechnischer Infrastruktur und
            verbundenen Dienstleistungen (z.&nbsp;B. Speicherplatz und/oder
            Rechenkapazitäten);
            <strong>Dienstanbieter:</strong> Hetzner Online GmbH, Industriestr.
            25, 91710 Gunzenhausen, Deutschland;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://www.hetzner.com" target="_blank">
              https://www.hetzner.com
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a
              href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de"
              target="_blank"
            >
              https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection_en?prefLang=de.
            </a>
            . <strong>Auftragsverarbeitungsvertrag:</strong>
            <a
              href="https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/"
              target="_blank"
            >
              https://docs.hetzner.com/de/general/general-terms-and-conditions/data-privacy-faq/
            </a>
            .
          </li>
        </ul>
        <h2 id="m134">Einsatz von Cookies</h2>
        <p>
          Cookies sind kleine Textdateien bzw. sonstige Speichervermerke, die
          Informationen auf Endgeräten speichern und aus ihnen auslesen. Zum
          Beispiel, um den Log-in-Status in einem Nutzerkonto, einen
          Warenkorbinhalt in einem E-Shop, die aufgerufenen Inhalte oder
          verwendete Funktionen eines Onlineangebots zu speichern. Cookies
          können ferner in Bezug auf unterschiedliche Anliegen Einsatz finden,
          etwa zu Zwecken der Funktionsfähigkeit, der Sicherheit und des
          Komforts von Onlineangeboten sowie der Erstellung von Analysen der
          Besucherströme.
        </p>
        <p>
          <strong>Hinweise zur Einwilligung:&nbsp;</strong>Wir setzen Cookies im
          Einklang mit den gesetzlichen Vorschriften ein. Daher holen wir von
          den Nutzern eine vorhergehende Einwilligung ein, es sei denn, sie ist
          laut Gesetzeslage nicht gefordert. Eine Erlaubnis ist insbesondere
          nicht notwendig, wenn das Speichern und das Auslesen der
          Informationen, also auch von Cookies, unbedingt erforderlich sind, um
          den Nutzern einen von ihnen ausdrücklich gewünschten Telemediendienst
          (also unser Onlineangebot) zur Verfügung zu stellen. Die widerrufliche
          Einwilligung wird ihnen gegenüber deutlich kommuniziert und enthält
          die Informationen zur jeweiligen Cookie-Nutzung.
        </p>
        <p>
          <strong>
            Hinweise zu datenschutzrechtlichen Rechtsgrundlagen:&nbsp;
          </strong>
          Auf welcher datenschutzrechtlichen Grundlage wir die personenbezogenen
          Daten der Nutzer mithilfe von Cookies verarbeiten, hängt davon ab, ob
          wir sie um eine Einwilligung bitten. Falls die Nutzer akzeptieren, ist
          die Rechtsgrundlage der Verwertung ihrer Daten die erklärte
          Einwilligung. Andernfalls werden die mithilfe von Cookies verwerteten
          Daten auf Grundlage unserer berechtigten Interessen (z.&nbsp;B. an
          einem betriebswirtschaftlichen Betrieb unseres Onlineangebots und der
          Verbesserung seiner Nutzbarkeit) verarbeitet oder, falls dies im
          Rahmen der Erfüllung unserer vertraglichen Pflichten erfolgt, wenn der
          Einsatz von Cookies erforderlich ist, um unseren vertraglichen
          Verpflichtungen nachzukommen. Zu welchen Zwecken die Cookies von uns
          verwertet werden, darüber klären wir im Laufe dieser
          Datenschutzerklärung oder im Rahmen von unseren Einwilligungs- und
          Verarbeitungsprozessen auf.
        </p>
        <p>
          <strong>Speicherdauer:&nbsp;</strong>Im Hinblick auf die Speicherdauer
          werden die folgenden Arten von Cookies unterschieden:
        </p>
        <ul>
          <li>
            <strong>
              Temporäre Cookies (auch: Session- oder Sitzungscookies):
            </strong>
            Temporäre Cookies werden spätestens gelöscht, nachdem ein Nutzer ein
            Onlineangebot verlassen und sein Endgerät (z.&nbsp;B. Browser oder
            mobile Applikation) geschlossen hat.
          </li>
          <li>
            <strong>Permanente Cookies:</strong> Permanente Cookies bleiben auch
            nach dem Schließen des Endgeräts gespeichert. So können
            beispielsweise der Log-in-Status gespeichert und bevorzugte Inhalte
            direkt angezeigt werden, wenn der Nutzer eine Website erneut
            besucht. Ebenso können die mithilfe von Cookies erhobenen
            Nutzerdaten zur Reichweitenmessung Verwendung finden. Sofern wir
            Nutzern keine expliziten Angaben zur Art und Speicherdauer von
            Cookies mitteilen (z.&nbsp;B. im Rahmen der Einholung der
            Einwilligung), sollten sie davon ausgehen, dass diese permanent sind
            und die Speicherdauer bis zu zwei Jahre betragen kann.
          </li>
        </ul>
        <p>
          <strong>
            Allgemeine Hinweise zum Widerruf und Widerspruch (Opt-out):&nbsp;
          </strong>
          Nutzer können die von ihnen abgegebenen Einwilligungen jederzeit
          widerrufen und zudem einen Widerspruch gegen die Verarbeitung
          entsprechend den gesetzlichen Vorgaben, auch mittels der
          Privatsphäre-Einstellungen ihres Browsers, erklären.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
            Abs. 1 S. 1 lit. f) DSGVO).
          </li>
        </ul>
        <h2 id="m328">Plug-ins und eingebettete Funktionen sowie Inhalte</h2>
        <p>
          Wir binden Funktions- und Inhaltselemente in unser Onlineangebot ein,
          die von den Servern ihrer jeweiligen Anbieter (nachfolgend als
          &quot;Drittanbieter&quot; bezeichnet) bezogen werden. Dabei kann es
          sich zum Beispiel um Grafiken, Videos oder Stadtpläne handeln
          (nachfolgend einheitlich als &quot;Inhalte&quot; bezeichnet).
        </p>
        <p>
          Die Einbindung setzt immer voraus, dass die Drittanbieter dieser
          Inhalte die IP-Adresse der Nutzer verarbeiten, da sie ohne IP-Adresse
          die Inhalte nicht an deren Browser senden könnten. Die IP-Adresse ist
          damit für die Darstellung dieser Inhalte oder Funktionen erforderlich.
          Wir bemühen uns, nur solche Inhalte zu verwenden, deren jeweilige
          Anbieter die IP-Adresse lediglich zur Auslieferung der Inhalte
          anzuwenden. Drittanbieter können ferner sogenannte Pixel-Tags
          (unsichtbare Grafiken, auch als &quot;Web Beacons&quot; bezeichnet)
          für statistische oder Marketingzwecke einsetzen. Durch die
          &quot;Pixel-Tags&quot; können Informationen, wie etwa der
          Besucherverkehr auf den Seiten dieser Website, ausgewertet werden. Die
          pseudonymen Informationen können darüber hinaus in Cookies auf dem
          Gerät der Nutzer gespeichert werden und unter anderem technische
          Auskünfte zum Browser und zum Betriebssystem, zu verweisenden
          Websites, zur Besuchszeit sowie weitere Angaben zur Nutzung unseres
          Onlineangebots enthalten, aber auch mit solchen Informationen aus
          anderen Quellen verbunden werden.
        </p>
        <p>
          <strong>Hinweise zu Rechtsgrundlagen:</strong> Sofern wir die Nutzer
          um ihre Einwilligung in den Einsatz der Drittanbieter bitten, stellt
          die Rechtsgrundlage der Datenverarbeitung die Erlaubnis dar. Ansonsten
          werden die Nutzerdaten auf Grundlage unserer berechtigten Interessen
          (d. h. Interesse an effizienten, wirtschaftlichen und
          empfängerfreundlichen Leistungen) verarbeitet. In diesem Zusammenhang
          möchten wir Sie auch auf die Informationen zur Verwendung von Cookies
          in dieser Datenschutzerklärung hinweisen.
        </p>
        <ul className="m-elements">
          <li>
            <strong>Verarbeitete Datenarten:</strong> Nutzungsdaten (z. B.
            Seitenaufrufe und Verweildauer, Klickpfade, Nutzungsintensität und
            -frequenz, verwendete Gerätetypen und Betriebssysteme, Interaktionen
            mit Inhalten und Funktionen); Meta-, Kommunikations- und
            Verfahrensdaten (z. B. IP-Adressen, Zeitangaben,
            Identifikationsnummern, beteiligte Personen). Standortdaten (Angaben
            zur geografischen Position eines Gerätes oder einer Person).
          </li>
          <li>
            <strong>Betroffene Personen:</strong> Nutzer (z.&nbsp;B.
            Webseitenbesucher, Nutzer von Onlinediensten).
          </li>
          <li>
            <strong>Zwecke der Verarbeitung:</strong> Bereitstellung unseres
            Onlineangebotes und Nutzerfreundlichkeit.
          </li>
          <li>
            <strong>Aufbewahrung und Löschung:</strong> Löschung entsprechend
            Angaben im Abschnitt &quot;Allgemeine Informationen zur
            Datenspeicherung und Löschung&quot;. Speicherung von Cookies von bis
            zu 2 Jahren (Sofern nicht anders angegeben, können Cookies und
            ähnliche Speichermethoden für einen Zeitraum von zwei Jahren auf den
            Geräten der Nutzer gespeichert werden.).
          </li>
          <li className="">
            <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S. 1
            lit. a) DSGVO). Berechtigte Interessen (Art. 6 Abs. 1 S. 1 lit. f)
            DSGVO).
          </li>
        </ul>
        <p>
          <strong>
            Weitere Hinweise zu Verarbeitungsprozessen, Verfahren und Diensten:
          </strong>
        </p>
        <ul className="m-elements">
          <li>
            <strong>
              Einbindung von Drittsoftware, Skripten oder Frameworks (z.&nbsp;B.
              jQuery):{" "}
            </strong>
            Wir binden in unser Onlineangebot Software ein, die wir von Servern
            anderer Anbieter abrufen (z.&nbsp;B. Funktions-Bibliotheken, die wir
            zwecks Darstellung oder Nutzerfreundlichkeit&nbsp;unseres
            Onlineangebotes verwenden). Hierbei erheben die jeweiligen Anbieter
            die IP-Adresse der Nutzer und können diese zu Zwecken
            der&nbsp;Übermittlung der Software an den Browser der Nutzer sowie
            zu Zwecken der Sicherheit, als auch zur Auswertung und Optimierung
            ihres Angebotes verarbeiten. - Wir binden in unser Onlineangebot
            Software ein, die wir von Servern anderer Anbieter abrufen
            (z.&nbsp;B. Funktions-Bibliotheken, die wir zwecks Darstellung oder
            Nutzerfreundlichkeit&nbsp;unseres Onlineangebotes verwenden).
            Hierbei erheben die jeweiligen Anbieter die IP-Adresse der Nutzer
            und können diese zu Zwecken der&nbsp;Übermittlung der Software an
            den Browser der Nutzer sowie zu Zwecken der Sicherheit, als auch zur
            Auswertung und Optimierung ihres Angebotes verarbeiten;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Google Fonts (Bereitstellung auf eigenem Server): </strong>
            Bereitstellung von Schriftarten-Dateien zwecks einer
            nutzerfreundlichen Darstellung unseres Onlineangebotes;{" "}
            <strong>Dienstanbieter:</strong> Die Google Fonts werden auf unserem
            Server gehostet, es werden keine Daten an Google übermittelt;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO).
            </span>
          </li>
          <li>
            <strong>Google Fonts (Bezug vom Google Server): </strong>Bezug von
            Schriften (und Symbolen) zum Zwecke einer technisch sicheren,
            wartungsfreien und effizienten Nutzung von Schriften und Symbolen im
            Hinblick auf Aktualität und Ladezeiten, deren einheitliche
            Darstellung und Berücksichtigung möglicher lizenzrechtlicher
            Beschränkungen. Dem Anbieter der Schriftarten wird die IP-Adresse
            des Nutzers mitgeteilt, damit die Schriftarten im Browser des
            Nutzers zur Verfügung gestellt werden können. Darüber hinaus werden
            technische Daten (Spracheinstellungen, Bildschirmauflösung,
            Betriebssystem, verwendete Hardware) übermittelt, die für die
            Bereitstellung der Schriften in Abhängigkeit von den verwendeten
            Geräten und der technischen Umgebung notwendig sind. Diese Daten
            können auf einem Server des Anbieters der Schriftarten in den USA
            verarbeitet werden - Beim Besuch unseres Onlineangebotes senden die
            Browser der Nutzer ihre Browser HTTP-Anfragen an die Google Fonts
            Web API (d.&nbsp;h. eine Softwareschnittstelle für den Abruf der
            Schriftarten). Die Google Fonts Web API stellt den Nutzern die
            Cascading Style Sheets (CSS) von Google Fonts und danach die in der
            CCS angegebenen Schriftarten zur Verfügung. Zu diesen HTTP-Anfragen
            gehören (1) die vom jeweiligen Nutzer für den Zugriff auf das
            Internet verwendete IP-Adresse, (2) die angeforderte URL auf dem
            Google-Server und (3) die HTTP-Header, einschließlich des
            User-Agents, der die Browser- und Betriebssystemversionen der
            Websitebesucher beschreibt, sowie die Verweis-URL (d.&nbsp;h. die
            Webseite, auf der die Google-Schriftart angezeigt werden soll).
            IP-Adressen werden weder auf Google-Servern protokolliert noch
            gespeichert und sie werden nicht analysiert. Die Google Fonts Web
            API protokolliert Details der HTTP-Anfragen (angeforderte URL,
            User-Agent und Verweis-URL). Der Zugriff auf diese Daten ist
            eingeschränkt und streng kontrolliert. Die angeforderte URL
            identifiziert die Schriftfamilien, für die der Nutzer Schriftarten
            laden möchte. Diese Daten werden protokolliert, damit Google
            bestimmen kann, wie oft eine bestimmte Schriftfamilie angefordert
            wird. Bei der Google Fonts Web API muss der User-Agent die
            Schriftart anpassen, die für den jeweiligen Browsertyp generiert
            wird. Der User-Agent wird in erster Linie zum Debugging
            protokolliert und verwendet, um aggregierte Nutzungsstatistiken zu
            generieren, mit denen die Beliebtheit von Schriftfamilien gemessen
            wird. Diese zusammengefassten Nutzungsstatistiken werden auf der
            Seite &quot;Analysen&quot; von Google Fonts veröffentlicht.
            Schließlich wird die Verweis-URL protokolliert, sodass die Daten für
            die Wartung der Produktion verwendet und ein aggregierter Bericht zu
            den Top-Integrationen basierend auf der Anzahl der
            Schriftartenanfragen generiert werden kann. Google verwendet laut
            eigener Auskunft keine der von Google Fonts erfassten Informationen,
            um Profile von Endnutzern zu erstellen oder zielgerichtete Anzeigen
            zu schalten;
            <strong>Dienstanbieter:</strong> Google Ireland Limited, Gordon
            House, Barrow Street, Dublin 4, Irland;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Berechtigte Interessen (Art. 6
              Abs. 1 S. 1 lit. f) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://fonts.google.com/" target="_blank">
              https://fonts.google.com/
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            ; <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF). <strong>Weitere Informationen:</strong>
            <a
              href="https://developers.google.com/fonts/faq/privacy?hl=de"
              target="_blank"
            >
              https://developers.google.com/fonts/faq/privacy?hl=de
            </a>
            .
          </li>
          <li>
            <strong>Google Maps: </strong>Wir binden die Landkarten des Dienstes
            &quot;Google Maps&quot; des Anbieters Google ein. Zu den
            verarbeiteten Daten können insbesondere IP-Adressen und
            Standortdaten der Nutzer gehören;
            <strong>Dienstanbieter:</strong> Google Cloud EMEA Limited, 70 Sir
            John Rogerson’s Quay, Dublin 2, Irland;
            <span className="">
              <strong>Rechtsgrundlagen:</strong> Einwilligung (Art. 6 Abs. 1 S.
              1 lit. a) DSGVO);{" "}
            </span>
            <strong>Website:</strong>
            <a href="https://mapsplatform.google.com/" target="_blank">
              https://mapsplatform.google.com/
            </a>
            ; <strong>Datenschutzerklärung:</strong>
            <a href="https://policies.google.com/privacy" target="_blank">
              https://policies.google.com/privacy
            </a>
            . <strong>Grundlage Drittlandtransfers:</strong> Data Privacy
            Framework (DPF).
          </li>
          <li>
            <strong>Matomo: </strong> Wir verwenden die Open-Source-Software
            Matomo zur Analyse und statistischen Auswertung der Nutzung der
            Website. Die dadurch erhaltenen Informationen über die
            Websitenutzung werden ausschließlich an unsere Server übertragen und
            in pseudonymen Nutzungsprofilen zusammengefasst. Die Daten verwenden
            wir zur Auswertung der Nutzung der Website. Eine Weitergabe der
            erfassten Daten an Dritte erfolgt nicht. Die IP-Adressen werden
            anonymisiert (IPMasking), sodass eine Zuordnung zu einzelnen Nutzern
            nicht möglich ist.
          </li>
        </ul>
        <h2 id="m15">Änderung und Aktualisierung</h2>
        <p>
          Wir bitten Sie, sich regelmäßig über den Inhalt unserer
          Datenschutzerklärung zu informieren. Wir passen die
          Datenschutzerklärung an, sobald die Änderungen der von uns
          durchgeführten Datenverarbeitungen dies erforderlich machen. Wir
          informieren Sie, sobald durch die Änderungen eine Mitwirkungshandlung
          Ihrerseits (z.&nbsp;B. Einwilligung) oder eine sonstige individuelle
          Benachrichtigung erforderlich wird.
        </p>
        <p>
          Sofern wir in dieser Datenschutzerklärung Adressen und
          Kontaktinformationen von Unternehmen und Organisationen angeben,
          bitten wir zu beachten, dass die Adressen sich über die Zeit ändern
          können und bitten die Angaben vor Kontaktaufnahme zu prüfen.
        </p>
        <h2 id="m42">Begriffsdefinitionen</h2>
        <p>
          In diesem Abschnitt erhalten Sie eine Übersicht über die in dieser
          Datenschutzerklärung verwendeten Begrifflichkeiten. Soweit die
          Begrifflichkeiten gesetzlich definiert sind, gelten deren gesetzliche
          Definitionen. Die nachfolgenden Erläuterungen sollen dagegen vor allem
          dem Verständnis dienen.
        </p>
        <ul className="glossary">
          <li>
            <strong>Beschäftigte:</strong> Als Beschäftigte werden Personen
            bezeichnet, die in einem Beschäftigungsverhältnis stehen, sei es als
            Mitarbeiter, Angestellte oder in ähnlichen Positionen. Ein
            Beschäftigungsverhältnis ist eine rechtliche Beziehung zwischen
            einem Arbeitgeber und einem Arbeitnehmer, die durch einen
            Arbeitsvertrag oder eine Vereinbarung festgelegt wird. Es beinhaltet
            die Verpflichtung des Arbeitgebers, dem Arbeitnehmer eine Vergütung
            zu zahlen, während der Arbeitnehmer seine Arbeitsleistung erbringt.
            Das Beschäftigungsverhältnis umfasst verschiedene Phasen, darunter
            die Begründung, in der der Arbeitsvertrag geschlossen wird, die
            Durchführung, in der der Arbeitnehmer seine Arbeitstätigkeit ausübt,
            und die Beendigung, wenn das Beschäftigungsverhältnis endet, sei es
            durch Kündigung, Aufhebungsvertrag oder anderweitig.
            Beschäftigtendaten sind alle Informationen, die sich auf diese
            Personen beziehen und im Kontext ihrer Beschäftigung stehen. Dies
            umfasst Aspekte wie persönliche Identifikationsdaten,
            Identifikationsnummern, Gehalts- und Bankdaten, Arbeitszeiten,
            Urlaubsansprüche, Gesundheitsdaten und Leistungsbeurteilungen.
          </li>
          <li>
            <strong>Bestandsdaten:</strong> Bestandsdaten umfassen wesentliche
            Informationen, die für die Identifikation und Verwaltung von
            Vertragspartnern, Benutzerkonten, Profilen und ähnlichen Zuordnungen
            notwendig sind. Diese Daten können u.a. persönliche und
            demografische Angaben wie Namen, Kontaktinformationen (Adressen,
            Telefonnummern, E-Mail-Adressen), Geburtsdaten und spezifische
            Identifikatoren (Benutzer-IDs) beinhalten. Bestandsdaten bilden die
            Grundlage für jegliche formelle Interaktion zwischen Personen und
            Diensten, Einrichtungen oder Systemen, indem sie eine eindeutige
            Zuordnung und Kommunikation ermöglichen.
          </li>
          <li>
            <strong>Inhaltsdaten:</strong> Inhaltsdaten umfassen Informationen,
            die im Zuge der Erstellung, Bearbeitung und Veröffentlichung von
            Inhalten aller Art generiert werden. Diese Kategorie von Daten kann
            Texte, Bilder, Videos, Audiodateien und andere multimediale Inhalte
            einschließen, die auf verschiedenen Plattformen und Medien
            veröffentlicht werden. Inhaltsdaten sind nicht nur auf den
            eigentlichen Inhalt beschränkt, sondern beinhalten auch Metadaten,
            die Informationen über den Inhalt selbst liefern, wie Tags,
            Beschreibungen, Autoreninformationen und Veröffentlichungsdaten
          </li>
          <li>
            <strong>Kontaktdaten:</strong> Kontaktdaten sind essentielle
            Informationen, die die Kommunikation mit Personen oder
            Organisationen ermöglichen. Sie umfassen u.a. Telefonnummern,
            postalische Adressen und E-Mail-Adressen, sowie Kommunikationsmittel
            wie soziale Medien-Handles und Instant-Messaging-Identifikatoren.
          </li>
          <li>
            <strong>Meta-, Kommunikations- und Verfahrensdaten:</strong> Meta-,
            Kommunikations- und Verfahrensdaten sind Kategorien, die
            Informationen über die Art und Weise enthalten, wie Daten
            verarbeitet, übermittelt und verwaltet werden. Meta-Daten, auch
            bekannt als Daten über Daten, umfassen Informationen, die den
            Kontext, die Herkunft und die Struktur anderer Daten beschreiben.
            Sie können Angaben zur Dateigröße, dem Erstellungsdatum, dem Autor
            eines Dokuments und den Änderungshistorien beinhalten.
            Kommunikationsdaten erfassen den Austausch von Informationen
            zwischen Nutzern über verschiedene Kanäle, wie E-Mail-Verkehr,
            Anrufprotokolle, Nachrichten in sozialen Netzwerken und
            Chat-Verläufe, inklusive der beteiligten Personen, Zeitstempel und
            Übertragungswege. Verfahrensdaten beschreiben die Prozesse und
            Abläufe innerhalb von Systemen oder Organisationen, einschließlich
            Workflow-Dokumentationen, Protokolle von Transaktionen und
            Aktivitäten, sowie Audit-Logs, die zur Nachverfolgung und
            Überprüfung von Vorgängen verwendet werden.
          </li>
          <li>
            <strong>Nutzungsdaten:</strong> Nutzungsdaten beziehen sich auf
            Informationen, die erfassen, wie Nutzer mit digitalen Produkten,
            Dienstleistungen oder Plattformen interagieren. Diese Daten umfassen
            eine breite Palette von Informationen, die aufzeigen, wie Nutzer
            Anwendungen nutzen, welche Funktionen sie bevorzugen, wie lange sie
            auf bestimmten Seiten verweilen und über welche Pfade sie durch eine
            Anwendung navigieren. Nutzungsdaten können auch die Häufigkeit der
            Nutzung, Zeitstempel von Aktivitäten, IP-Adressen,
            Geräteinformationen und Standortdaten einschließen. Sie sind
            besonders wertvoll für die Analyse des Nutzerverhaltens, die
            Optimierung von Benutzererfahrungen, das Personalisieren von
            Inhalten und das Verbessern von Produkten oder Dienstleistungen.
            Darüber hinaus spielen Nutzungsdaten eine entscheidende Rolle beim
            Erkennen von Trends, Vorlieben und möglichen Problembereichen
            innerhalb digitaler Angebote
          </li>
          <li>
            <strong>Personenbezogene Daten:</strong> &quot;Personenbezogene
            Daten&quot; sind alle Informationen, die sich auf eine
            identifizierte oder identifizierbare natürliche Person (im Folgenden
            &quot;betroffene Person&quot;) beziehen; als identifizierbar wird
            eine natürliche Person angesehen, die direkt oder indirekt,
            insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu
            einer Kennnummer, zu Standortdaten, zu einer Online-Kennung
            (z.&nbsp;B. Cookie) oder zu einem oder mehreren besonderen Merkmalen
            identifiziert werden kann, die Ausdruck der physischen,
            physiologischen, genetischen, psychischen, wirtschaftlichen,
            kulturellen oder sozialen Identität dieser natürlichen Person sind.
          </li>
          <li>
            <strong>Protokolldaten:</strong> Protokolldaten sind Informationen
            über Ereignisse oder Aktivitäten, die in einem System oder Netzwerk
            protokolliert wurden. Diese Daten enthalten typischerweise
            Informationen wie Zeitstempel, IP-Adressen, Benutzeraktionen,
            Fehlermeldungen und andere Details über die Nutzung oder den Betrieb
            eines Systems. Protokolldaten werden oft zur Analyse von
            Systemproblemen, zur Sicherheitsüberwachung oder zur Erstellung von
            Leistungsberichten verwendet.
          </li>
          <li>
            <strong>Standortdaten:</strong> Standortdaten entstehen, wenn sich
            ein mobiles Gerät (oder ein anderes Gerät mit den technischen
            Voraussetzungen einer Standortbestimmung) mit einer Funkzelle, einem
            WLAN oder ähnlichen technischen Mitteln und Funktionen der
            Standortbestimmung, verbindet. Standortdaten dienen der Angabe, an
            welcher geografisch bestimmbaren Position der Erde sich das
            jeweilige Gerät befindet. Standortdaten können z.&nbsp;B. eingesetzt
            werden, um Kartenfunktionen oder andere von einem Ort abhängige
            Informationen darzustellen.
          </li>
          <li>
            <strong>Verantwortlicher:</strong> Als &quot;Verantwortlicher&quot;
            wird die natürliche oder juristische Person, Behörde, Einrichtung
            oder andere Stelle, die allein oder gemeinsam mit anderen über die
            Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
            entscheidet, bezeichnet.
          </li>
          <li>
            <strong>Verarbeitung:</strong> &quot;Verarbeitung&quot; ist jeder
            mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang
            oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
            Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang
            mit Daten, sei es das Erheben, das Auswerten, das Speichern, das
            Übermitteln oder das Löschen.
          </li>
          <li>
            <strong>Vertragsdaten:</strong> Vertragsdaten sind spezifische
            Informationen, die sich auf die Formalisierung einer Vereinbarung
            zwischen zwei oder mehr Parteien beziehen. Sie dokumentieren die
            Bedingungen, unter denen Dienstleistungen oder Produkte
            bereitgestellt, getauscht oder verkauft werden. Diese Datenkategorie
            ist wesentlich für die Verwaltung und Erfüllung vertraglicher
            Verpflichtungen und umfasst sowohl die Identifikation der
            Vertragsparteien als auch die spezifischen Bedingungen und
            Konditionen der Vereinbarung. Vertragsdaten können Start- und
            Enddaten des Vertrages, die Art der vereinbarten Leistungen oder
            Produkte, Preisvereinbarungen, Zahlungsbedingungen,
            Kündigungsrechte, Verlängerungsoptionen und spezielle Bedingungen
            oder Klauseln umfassen. Sie dienen als rechtliche Grundlage für die
            Beziehung zwischen den Parteien und sind entscheidend für die
            Klärung von Rechten und Pflichten, die Durchsetzung von Ansprüchen
            und die Lösung von Streitigkeiten.
          </li>
          <li>
            <strong>Zahlungsdaten:</strong> Zahlungsdaten umfassen sämtliche
            Informationen, die zur Abwicklung von Zahlungstransaktionen zwischen
            Käufern und Verkäufern benötigt werden. Diese Daten sind von
            entscheidender Bedeutung für den elektronischen Handel, das
            Online-Banking und jede andere Form der finanziellen Transaktion.
            Sie beinhalten Details wie Kreditkartennummern, Bankverbindungen,
            Zahlungsbeträge, Transaktionsdaten, Verifizierungsnummern und
            Rechnungsinformationen. Zahlungsdaten können auch Informationen über
            den Zahlungsstatus, Rückbuchungen, Autorisierungen und Gebühren
            enthalten.
          </li>
        </ul>
        <p className="seal">
          <a
            href="https://datenschutz-generator.de/"
            title="Rechtstext von Dr. Schwenke - für weitere Informationen bitte anklicken."
            target="_blank"
            rel="noopener noreferrer nofollow"
          >
            Erstellt mit kostenlosem Datenschutz-Generator.de von Dr. Thomas
            Schwenke
          </a>
        </p>
        <Footer />
      </InnerWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;
const InnerWrapper = styled.div`
  padding: 15px;
  width: 60vw;
  @media (max-width: 1700px) {
    width: 80vw;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;
`;
const Close = styled.a`
  color: #165e16;
  font-size: 2em;
  margin-top: 0.67em;
  margin-bottom: 0.67em;
  margin-left: 0;
  margin-right: 0;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 900px) {
    display: none;
  }
`;

export default Privacy;
