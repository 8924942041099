import React from "react";
import { Route, Switch, Link, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  createMuiTheme,
  ThemeProvider,
  StylesProvider,
} from "@material-ui/core/styles";

import SiteContainer from "./src/components/General/SiteContainer";

import Explore from "./src/components/Explore/Explore";
import Success from "./src/components/Success/Success";
import Failure from "./src/components/Failure/Failure";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import Imprint from "./src/components/Imprint/Imprint";
import Agb from "./src/components/Agb/Agb";
import Privacy from "./src/components/Privacy/Privacy";

const client = new ApolloClient({
  uri: process.env.REACT_APP_BACKEND_URL,
  cache: new InMemoryCache(),
});

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: ["Montserrat"].join(","),
  },
  palette: {
    primary: {
      main: "#003D5B",
    },
    secondary: {
      main: "#447604",
    },
    error: {
      main: "#D1495B",
    },
  },
});

const App = () => {
  const location = useLocation();
  return (
    <ApolloProvider client={client}>
      <ThemeProvider theme={muiTheme}>
        <StylesProvider injectFirst>
          <AnimatePresence>
            <Switch location={location} key={location.pathname}>
              <Route path="/success">
                <SiteContainer position={"top"}>
                  <Success />
                </SiteContainer>
              </Route>
              <Route path="/failure">
                <SiteContainer position={"top"}>
                  <Failure />
                </SiteContainer>
              </Route>
              <Route path="/imprint">
                <SiteContainer position={"top"}>
                  <Imprint />
                </SiteContainer>
              </Route>
              <Route path="/agb">
                <SiteContainer position={"top"}>
                  <Agb />
                </SiteContainer>
              </Route>
              <Route path="/privacy">
                <SiteContainer position={"top"}>
                  <Privacy />
                </SiteContainer>
              </Route>
              <Route path="/">
                <SiteContainer position={"bottom"}>
                  <Explore />
                </SiteContainer>
              </Route>
            </Switch>
          </AnimatePresence>
        </StylesProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
};

export default App;
